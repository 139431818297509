.loginContainer {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.loginSection {
    border: solid;
    display: flex;
    height: 300px;
    width: 300px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}